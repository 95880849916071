footer {
  background-color: var(--greyLightMore);
  padding-bottom: 30px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 57px 0 25px 0;
  // margin-bottom: 25px;
  // border-bottom: 1px solid rgba(139,161,183, 0.5);

  &__phone a{
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    &:hover {
      text-decoration-thickness: from-font;
    }
  }
}

.footer-main {
  display: flex;
  justify-content: space-between;
  gap: 86px;
  // padding-bottom: 34px;
  // border-bottom: 1px solid rgba(139,161,183, 0.5);
}

.footer-main-list {
  list-style: none;
  max-width: 250px;
  li {
    margin-bottom: 20px;
    color: #576676;
    a {
      font-size: var(--fontSmallSize);
    }
  }
}

.footer-main-title {
  // text-transform: uppercase;
  font-weight: 700;
  font-size: var(--fontSize);
  line-height: 24px;
  margin-bottom: 28px;
  max-width: 150px;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  border-bottom: 1px solid rgba(139,161,183, 0.5);

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 881px;
    gap: 100px;

    a {
      line-height: 0;
      transition: all 0.3s;
      position: relative;
      &:hover {
        filter: brightness(120%);
        p {
          opacity: 1;
          filter: brightness(100%)
        }
      }
    }

    p {
      position: absolute;
      right: -30px;
      line-height: 16px;
      width: 200%;
      max-width: 100px;
      background-color: #fff;
      border: 1px solid rgba(139, 161, 183, 0.5);
      border-radius: 2px;
      margin-top: 5px;
      padding: 2px;
      text-align: center;
      opacity: 0;
      transition: all 0.3s;
    }
  }
}

.footer-socials {
  display: flex;
  gap: 19px;

  .icon-youtube:before,
  .icon-facebook:before,
  .icon-rss:before {
    font-size: 17px;
  }

  .icon-youtube:hover:before {
    color: #e42b26;
  }
  .icon-facebook:hover:before {
    color: #3b5998;
  }
  .icon-rss:hover:before {
    color: #ea7819;
  }
}

.free-line {
  display: flex;
  gap: 5px;
  align-items: center;
  padding-top: 5px;
}

.footer-phone {
  font-weight: 300;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.902);
}

.icon-phone-2:before {
  font-size: 12px;
}

.copyright {
  a {
    font-size: 14px !important;
  }
}

  @media screen and (max-width: 1440px) { 
    .footer-main {
      gap:74px;
    }
    // .footer-main-list {
    //   max-width: 171px;
    // }
  }

  @media screen and (max-width: 1200px) { 
    .footer-main {
      gap:67px;
    }
    .footer-main-list {
      max-width: 200px;
    }
    .footer-bottom__inner {
      gap: 80px;
    }
  }

  @media screen and (max-width: 1025px) {
    .footer-main {
      flex-wrap: wrap;
      gap: 40px;
    }
    .footer-main__inner {
      width: 45%;
    }
    .footer-main-title,
    .footer-main-list {
      max-width: 100%;
    }
    .footer-bottom__inner {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 880px) {
    .footer-bottom__inner {
      max-width: 435px;
    }
  }

  @media screen and (max-width: 575px) {
    .footer-main__inner {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .footer-main-title, .footer-main-list {
      max-width: 233px;
    }
    .footer-bottom__inner {
      max-width: 350px;
      gap: 43px;
    }
  }

  @media screen and (max-width: 400px) { 
    .footer-top {
      gap: 6px;
    }
    .social-inner {
      gap: 5px;
    }
  }