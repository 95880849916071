  .header {
    font-family: var(--fontFamily);
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--whiteLOE);
    z-index: 100;

    &__inner {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      max-height: 60px;
      gap: 80px;
      width: 100%;
      // box-shadow: 0px 4px 10px 0px rgba(87, 102, 118, 0.1) ;
    }

    &__logo {
      line-height: 0;
      display: flex;

      img {
        width: 45px;
        height: 60px;
        margin: 0 10px;
        object-fit: cover;
        object-position: center;
      }

      a {
        text-decoration: none;
        font-size: 21px;
      }
    }
    .menu {
      &__list {
        list-style: none;
        display: flex;
        // gap: 40px;
      }

     &__list-item {
      padding-right: 40px;

      &:last-child {
        padding-right: 0;
      }

      button {
        font-size: var(--fontSmallSize);
        line-height: 21px;
        font-family: var(--fontFamilyMedium);
        color: var(--fontColor);
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
     
      &:hover {
        .dropdown {
          clip: auto;
          opacity: 1;
          z-index: -1;
          li {
            opacity: 1;
          }
        }
        .drop {
          &:after { 
            width: 100%;
          }
        } 
      }
      
      .drop.active:after {
        width: 100%;
        background-color:#8b8b8b;
      }

      .drop {
        display: block;
        padding: 40px 0;
        position: relative;
        font-size: var(--fontSize);
        line-height: 23px;

        &:after {
              content: "";
              position: absolute;
              height: 1px;
              width: 0;
              bottom: -5px;
              left: 0;
              top: 65%;
              transform: translateY(-70%);
              background-color: rgba(139,139,139, 0.4);
              transition: all 0.3s;
        }
        &:hover {
          text-decoration: none;
        }
        }
      }
    }
    
    .social-search {
      display: flex;
      padding-right: 15px;

      a {
        padding: 40px 0;
      }
    }

    .search-svg:hover {
      circle {
        fill: var(--greenHover);
      }
    }

    .social-svg:hover {
      circle {
        fill: var(--orangeLight1);
      }
    }

    .search-item {
      padding: 40px 20px 40px 20px;

      button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;
      }

      form {
        max-width: 740px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          border: 1px solid #C4CBD2;
          border-radius: 4px 0 0 4px;
          background-color: transparent;
          padding: 6px 23px 5px 23px;
          width: 100%;
          font-weight: 400;
          font-size: var(--fontSize);
          line-height: 27px;
          color: var(--fontColor);
          &:focus { 
            outline: none; 
            border-color: var(--greyLight);
          }
          &::placeholder {
            color: var(--greyLight);
          }
        }

        button {
          font-weight: 700;
          font-size: var(--fontSmallSize);
          line-height: 20px;
          color: var(--whiteLOE);
          padding: 10px 24px;
          border: none;
          background: var(--greenDark);
          border-radius: 0 4px 4px 0;
          transition: all 0.3s;

          &:hover {
            background-color: var(--greenHover);
          }
        }
      }
    }
  }

  .search-inner {
    display: flex;
  }

  .social-inner {
    display: flex;
    gap: 12px;

    a:hover {
      svg {
        rect{
          fill: var(--greyLight);
        }
      }
    }
  }

  .dropdown {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    opacity: 0;
    clip: rect(0,0,0,0);
    top: 100px;
    left: 50%;
    position: absolute;
    background-color: var(--whiteLOE);
    gap: 122px;
    width: 100%;
    transform: translateX(-50%);
    z-index: -2;
    padding: 40px 40px 40px 0;
    box-shadow: 0px 4px 10px 0px rgba(87, 102, 118, 0.2);
    // transition: opacity 0.3s ease-in-out;

    ul {
      max-width: 305px;
      font-family: var(--fontFamilyMedium);
      font-weight: 400;
      font-size: var(--fontSize);
      line-height: 23px;
      list-style: none;
      li {
        margin-bottom: 30px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        a {
          font-size: var(--fontSize);
        }

        &::before {
          content: "\2022";
          color: #98DB00;
          font-weight: bold;
          display: inline-block; 
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  .dropdown.active {
    padding: 20px 0 20px 0;
    clip: auto;
    opacity: 1;
  }

  .menu-mobile { 
    width: 60px;
    height: 60px;
    background-color: var(--greenMain);
    display: none;
    align-items: center;
    justify-content: center;
    border: none;

    span {
      width: 24px;
      height: 4px;
      background-color: var(--whiteLOE);
      position: relative;
      &::after,
      &::before {
        content: '';
        width: 24px;
        height: 4px;
        background-color: var(--whiteLOE);
        position: absolute;
        transform: rotate(0);
        transition: transform 0.3s;
        left: 0;
      }
      &::after {
        top: 8px;
      }
      &::before {
        top: -8px;
      }
    }
  }

  .menu-mobile.active{
    span {
      background-color: transparent;
    }
    span::after {
      top: 0;
      transform: rotate(45deg);
    }
    span::before {
      top: 0;
      transform: rotate(-45deg);
    }
  }

  .menu .social-inner {
    display: none;
  }

  .search-result {
    padding: 0 23px;
    position: absolute;
    background-color: var(--whiteLOE);
    width: 85%;
    max-width: 760px;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 4px 20px 0px #57667680;
    top: 67px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: auto;

    img {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
      
    &::-webkit-scrollbar-thumb {
      background: var(--greenMain);
      border-radius: 12px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: var(--greenHover);
    }

    &__title { 
      font-size: var(--fontLinksSize);
      line-height: 24px;
      margin-bottom: 0;
    }

    &__item {
      border-bottom: 1px solid var(--greenLight);
      padding: 13px 0 0 0 !important;
      color: var(--fontColor) !important;

      &:last-child {
        border-bottom: none;
      }

      p,h3,strong,span {
        margin-top: 5px;
        max-height: 44px;
        overflow: hidden;
        font-size: var(--fontSize) !important;
        line-height: var(--lineHeight) !important;
        text-transform: lowercase;
        font-weight: 300 !important;
        color:  var(--fontColor) !important;
      }
    }
  }

  .menu.small {
    display: none;
  }

  // visual impairment

  .visual-inner {
    display: flex;
    button {
      display: flex;
      align-items: center;
      font-family: var(--fontFamilyMedium);
      font-size: var(--fontSmallSize);
      color: var(--fontColor);
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;

      &:hover {
        .eye-svg {
          transform: scale(1.1);
        }
      }
    }
  }

  .eye-inner {
    position: relative;
    display: inline-block;
  }

  .eye-svg {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-left: 10px;
    transition: all 0.3s;
    cursor: pointer;
    path {
      transition: all 0.3s;
      fill: var(--fontColor);
    }
  }

  .eye {
    transition: all 0.3s;
    position: relative;
  }

  .eye-panel {
    padding: 10px 20px;
    display: flex;
    gap: 100px;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .eye-inner {
      display: flex;
      align-items: center;
      gap: 10px;
      user-select: none;
      cursor: pointer;

      &:hover {
        .eye-svg {
          path {
            fill: #a7a7a7;
          }
        }
      }
    }

    .eye-common {
      p {
        font-family: var(--fontFamilyMedium);
        color: var(--fontColor);
        font-size: var(--fontSize);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .eye-font, 
  .eye-color {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      font-family: var(--fontFamilyMedium);
      color: var(--fontColor);
      font-size: var(--fontSize);
      margin-right: 5px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .eye-font {
    .eye-font-a.active {
      border: 5px solid #2F3133;
    }
  }

  .eye-color {  
    .eye-font-a.active {
      background-color: #2F3133;
      color: #FFFFFF;
      border: 5px solid #FFFFFF;
    }
  }

  .eye-font-a {
    user-select: none;
    cursor: pointer;
    background-color: #d8d8d8;
    color: #3E4042;
    padding: 5px 10px;
  }


  @media screen and (max-width: 1545px) {
    .header {
      .search-item {
        padding: 40px 10px 40px 0px;
      }
      .social-search {
        padding-right: 10px;
      }
    }
    .social-inner {
      gap: 8px;
    }
    .eye-svg {
      margin: 0 8px;
    }
  }

  // @media screen and (max-width: 1375px) {
  //   .header {
  //     .search-item {
  //       padding: 40px 10px 40px 0px;
  //     }
  //     .social-search {
  //       padding-right: 10px;
  //     }
  //   }
  //   .social-inner {
  //     gap: 8px;
  //   }
  //   .eye-svg {
  //     margin-left: 10px;
  //   }
  // }


  @media screen and (max-width: 1153px) {
    .menu.small {
      display: block;
      .menu__list {
        gap: 20px;
        &-item {
          padding-right: 0;
        }
      }
    }

    .dropdown {
      padding: 40px;
    }

    .header {
      .menu.big {
        position: absolute;
        top: 60px;
        background-color: var(--whiteLOE);
        padding: 24px;
        max-height: calc(100vh - 124px);
        height: 100vh;
        box-shadow: 0px 4px 10px 0px rgba(87, 102, 118, 0.2);
        width: 100%;
        z-index: -1;
        transform: translateX(-100%);
        transition: transform 0.3s;
        border-radius: 0 0 12px 0;
        overflow-y: auto;

      .menu {
        &__list {
          flex-direction: column;
          gap: 0;
          max-width: 600px;
          padding-right: 24px;
        }

        &__list-item.company,
        &__list-item.pobut,
        &__list-item.important {
          display: none;
        }

        &__list-item {
          border-bottom: 1px solid #C4CBD2;
          position: relative;
          padding: 0 24px;
          font-size: var(--fontLinksSize);
          line-height: 30px;

          &:hover {
            .drop::after {
              width: auto;
            }
          }

          .dropdown {
            position: relative;
            flex-direction: column;
            top: 0;
            padding: 0 24px 0 56px;
            gap: 0;
            box-shadow: none;
            transition: none;
            display: none;
          }
          
          a {
            &::after{
              display: none;
            }
          }

          .drop {
            padding: 20px 0;
            font-size: var(--fontLinksSize);
            line-height: 30px;  
            width: 100%;
            text-align: left;
            &::after{
              content: url('../assets/img/arrow-down.svg');
              width: 16px;
              position: absolute;
              height: auto;
              right: 0px;
              bottom: auto;
              top: 50%;
              left: auto;
              transform: translateY(-50%);
              background-color: transparent;
            }
          }

          .drop.active~.dropdown {
            display: flex;
            z-index: 1;
          }
          .drop.active::after {
            width: auto;
            transform: rotate(180deg);
            top: 30%;
            background-color: transparent;
          }

          button {
            position: relative;
            &::after{
              content: url('../assets/img/arrow-down.svg');
              width: 16px;
              position: absolute;
              right: 24px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          }
        }
      }
    }

    .header .menu.active {
      transform: translateX(0);
    }

    .menu-mobile {
      display: flex;
    }

    .dropdown  {
      gap: 55px;
      top: 80px;
        ul {
          max-width: 285px;
        }
    }

    // .header__inner {
    //   max-height: 80px;
    // }
    
    // .header__logo{
    //   img{
    //     width: 160px;
    //     height: 80px;
    //   }
    // }

    .page {
      padding-top: 80px;
    }

    .visual-inner {
      button span{
        display: none;
      }
    }
  }

  // @media screen and (max-width: 1203px) {  
  //   .visual-inner {
  //     button span{
  //       display: none;
  //     }
  //   }
  // }

  @media screen and (max-width: 1082px) { 
    .menu.small {
      display: none;
    }

    .visual-inner {
      button span{
        display: block;
      }
    }

    .eye-svg {
      margin: 0 15px;
    }

    .header{
      .menu.big {
        .menu {
          &__list-item.company,
          &__list-item.pobut,
          &__list-item.important {
            display: block;
          }
        }
      }

      &__inner {
        gap: 17px;
      }
      .search-item{
        padding-right: 17px;
      }
      .search-item form {
        padding-left: 20px;
      }
    }

    .dropdown.active {
      padding: 20px 20px 20px 0;
    }

    .eye-panel {
      gap: 50px;
    }
  }

  @media screen and (max-width: 795px) { 
    .eye-panel {
      gap: 20px;
    }
  }

  @media screen and (max-width: 735px) {  
    .eye-panel {
      padding: 10px 50px;
    }
    .visual-inner {
      button span{
        display: none;
      }
    }
  }

  @media screen and (max-width: 620px) { 
    .header {
      .search-item form input {
        padding: 6px 15px 5px 15px;
      }
      .social-search {
        flex-direction: row-reverse;
      }
      .social-inner {
        display: none;
      }
      .search-item {
        padding-right: 5px;
      }
    }

    .menu .social-inner {
      display: flex;
      padding-top: 40px;
      padding-left: 20px;
    }
  }